@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    main {
        background: rgb(61, 1, 125);
    }

    :is(h1, h2, h3, p) {
        color: white;
    }

    h1 {
        font-size: 3.5rem;
        margin-bottom: 1rem;
        font-family: 'Bitter', serif;
    }

    h2 {
        font-size: 3rem;
        margin-bottom: 1rem;
        font-family: 'Bitter', serif;
    }

    h3 {
        font-size: 2rem;
        margin-bottom: 1rem;
        font-family: 'Bitter', serif;
    }

    p {
        font-size: 1.5rem;
        line-height: 3rem;
        margin: 1rem 0;
        font-family: 'Bitter', sans-serif;
    }

    :is(p:last-of-type) {
        margin-bottom: 0;
    }

    nav a,
    .CTA-btn,
    .download-btn {
        background: linear-gradient(90deg, #e8ea85, #8b6c1f);
        background-size: 200% 100%;
        border-radius: 0.5rem;
        color: black;
        text-decoration: none;
        font-size: 2rem;
        padding: 1rem 2rem;
        width: 15rem;
        text-align: center;
        font-family: 'Bitter', serif;

        &:hover {
            animation: swipeAnimation 0.5s linear forwards;
        }
    }

    .CTA-btn,
    .download-btn {
        width: 75%;
        font-weight: 800;
    }

    .gold-shadow {
        box-shadow: 0 0 25px 5px rgba(232, 234, 133, 0.5);
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 1;
        pointer-events: all;
        transition: all 0.3s ease-in-out;
    }
    .modal-content {
        background: white;
        border-radius: 0.5rem;
        padding: 2rem;
        width: 40%;
        max-width: 90%;
        text-align: center;
        position: relative;
    }
    .close-msg {
        position: absolute;
        top: 1%;
        left: 5%;
        font-size: 1.5rem;
        padding: 1rem;
        cursor: pointer;
        color: white;
        text-align: center;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 10px;
    }

    .modal-msg {
        font-size: 1.5rem;
        padding: 1rem;
        cursor: pointer;
        color: white;
        text-align: center;
        background: rgba(0, 0, 0, 0.9);
        border-radius: 10px;
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 1;
        pointer-events: all;
        transition: all 0.3s ease-in-out;
    }

    .aud-winnie,
    .aud-urine {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 0.5rem;
        width: 20rem;
        height: 10rem;
        margin: 0 auto;
    }

    .aud-winnie {
        background-image: url('./assets/winnie-kids.jpg');
    }

    .aud-urine {
        background-image: url('./assets/urinetown.jpg');
    }

    .spotlight {
        margin: 0 auto;
        width: 12rem;
        height: 12rem;
        border-radius: 50%;
        background: linear-gradient(90deg, #e8ea85, #8b6c1f);
        transition: transform 0.3s ease-in-out;
    }

    .spotlight:hover {
        transform: scale(1.5);
    }

    .glow {
        cursor: pointer;
        &:hover {
            animation: glow 0.5s linear forwards;
        }
    }

    /* Animations */

    @keyframes swipeAnimation {
        0% {
            background-position: 0 0;
            color: black;
        }
        100% {
            background-position: -100% 0;
            color: white;
        }
    }

    @keyframes glow {
        0% {
            box-shadow: none;
        }
        100% {
            box-shadow:
                0 0 10px #ffffff,
                0 0 20px #ffffff,
                0 0 30px #ffffff;
        }
    }

    @media (max-width: 499px) {
        h1 {
            font-size: 2rem;
            margin-bottom: 1rem;
        }

        h2 {
            font-size: 1.75rem;
            margin-bottom: 1rem;
        }

        h3 {
            font-size: 1.25rem;
            margin-bottom: 1rem;
        }

        p {
            font-size: 1rem;
            margin: 1rem 0;
            line-height: 2rem;
        }

        nav a {
            font-size: 1rem;
            padding: 1rem 2rem;
        }

        .CTA-btn,
        .download-btn {
            font-size: 1.5rem;
            padding: 1rem 2rem;
            width: 70%;
        }

        .download-btn {
            width: 90%;
            padding: 0.5rem;
        }

        .close-btn {
            font-size: 1rem;
            padding: 0.5rem;
        }

        .modal-content {
            width: 90%;
        }

        .aud-urine,
        .aud-winnie {
            width: 15rem;
            height: 7.5rem;
        }

        .spotlight {
            width: 8rem;
            height: 8rem;
        }
    }

    @media (min-width: 500px) and (max-width: 1024px) {
        h1 {
            font-size: 2.5rem;
            margin-bottom: 1rem;
        }

        h2 {
            font-size: 2rem;
            margin-bottom: 1rem;
        }

        h3 {
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }

        p {
            font-size: 1rem;
            margin: 1rem 0;
            line-height: 2rem;
        }

        nav a {
            font-size: 1rem;
            padding: 1rem 2rem;
            width: 10rem;
        }

        .CTA-btn {
            font-size: 1.5rem;
            padding: 1rem 2rem;
            width: 50%;
        }

        .spotlight {
            width: 8rem;
            height: 8rem;
        }

        .modal-content {
            width: 60%;
        }
    }

    @media (min-width: 1281px) and (max-width: 1535px) {
        .CTA-btn {
            width: 40%;
        }
    }
}
